import React, {FC, useState} from "react";
import {AppShell, Button, Loader, NavLink} from "@mantine/core";
import {NavLink as RouterNavlink, useParams} from "react-router-dom";
import NewRoomModal from "./NewRoomModal";
import {createRoom, listRooms} from "./roomEndpoints";
import {useQuery, useQueryClient} from "react-query";

const RoomNavBar:FC = () => {
    const { roomId } = useParams();
    const [newRoomModalIsOpen, setNewRoomModalIsOpen] = useState(false);

    const query = useQuery('rooms', listRooms)
    const queryClient = useQueryClient()

    const onSubmitModal = async (roomName: string) => {
        setNewRoomModalIsOpen(false)
        await createRoom(roomName)
        queryClient.invalidateQueries('rooms')
    }

    return (
        <AppShell.Navbar p="md">
            <>
                <div>
                    Your Virtual Data Rooms
                </div>
                {query.isLoading ? <Loader/> : query.data?.map((room) => {
                    return (
                        <NavLink
                            active={roomId === room.room_id}
                            component={RouterNavlink}
                            to={`/room/${room.room_id}`}
                            key={room.room_id}
                            label={room.room_name}
                        />
                    )
                })
                }
                <Button onClick={() => setNewRoomModalIsOpen(true)}>
                    Create new room
                </Button>
                <NewRoomModal open={newRoomModalIsOpen} onClose={() => setNewRoomModalIsOpen(false)} onSubmit={onSubmitModal}/>
            </>
        </AppShell.Navbar>
    )
}

export default RoomNavBar