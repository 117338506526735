import styles from './RoomOutlet.module.css'

import {FC, useLayoutEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {listRooms, Room} from "../RoomNavBar/roomEndpoints";
import {ActionIcon, Anchor, Breadcrumbs, Card, Loader, Text} from "@mantine/core";
import FileUploadBox from "./FileUploadBox";
import {
    deleteDirectory,
    deleteFile,
    getFile,
    listFiles,
    moveDirectory,
    moveFile,
    renameDirectory,
    renameFile
} from "../RoomNavBar/fileEndpoints";
import {createFileHierarchy, DirectoryNode, FileNode, findNodeByPath, isDirectoryNode, isFileNode} from "./fileUtils";
import {IconAdjustments, IconFileTypePdf, IconFolder, IconTrash} from "@tabler/icons-react";
import EditDirectoryModal from "./EditDirectoryModal";
import EditFileModal from "./EditFileModal";



const RoomOutlet:FC = () => {
    const queryClient = useQueryClient()
    const { roomId } = useParams();
    const [currentPath, setCurrentPath] = useState("/")
    // Reusing rooms fileListQuery to get info about room to save implementing another endpoint.
    const roomListQuery = useQuery('rooms', listRooms)
    const fileListQuery = useQuery(['files', roomId], () => listFiles(roomId || ""))

    const [editDirectoryModalName, setEditDirectoryModalName] = useState<string | false>(false)
    const [editFileName, setEditFileName] = useState<string | false>(false)
    const [editFileId, setEditFileId] = useState<string | false>(false)

    // Reset current path when switching rooms
    useLayoutEffect(() => {
        setCurrentPath("/")
    }, [roomId]);


    const fileHierarchy: DirectoryNode = useMemo(() => {
        if (fileListQuery.data) {
            return createFileHierarchy(fileListQuery.data)
        }
        const rootNode: DirectoryNode = {
            type: 'directory',
            name: "root",
            children: [] as Array<DirectoryNode | FileNode>,
        }
        return rootNode
    }, [fileListQuery.data])

    const currentNode = useMemo(() => {
        return findNodeByPath(fileHierarchy, currentPath)
    }, [fileHierarchy, currentPath])

    // Ideally we would just want to fetch the room directly by it's ID but doing this for the sake of time
    let room: Room | undefined
    roomListQuery.data?.forEach((roomInList) => {
        if(roomInList.room_id === roomId) {
            room = roomInList
        }
    })

    if (!room) {
        return (
            <div>
                <Loader/>
            </div>
        )
    }

    if (!roomId) {
        throw new Error("roomId required in path for this component")
    }

    const submitMoveDirectory = async (newPath: string) => {
        const directoryName = editDirectoryModalName
        setEditDirectoryModalName(false)
        if (directoryName) {
            await moveDirectory(currentPath, newPath, directoryName, roomId)
            queryClient.invalidateQueries(['files', roomId])

        }
    }

    const submitRenameDirectory = async (newDirectoryName: string) => {
        const directoryName = editDirectoryModalName
        setEditDirectoryModalName(false)
        if (directoryName) {
            await renameDirectory(currentPath, directoryName, newDirectoryName, roomId)
            queryClient.invalidateQueries(['files', roomId])

        }
    }

    const submitMoveFile = async (newPath: string, fileId: string) => {
        const fileName = editFileName
        setEditFileId(false)
        setEditFileName(false)
        if (fileName) {
            await moveFile(newPath, fileId, roomId)
            queryClient.invalidateQueries(['files', roomId])
        }
    }

    const submitRenameFile = async (newName: string, fileId: string) => {
        const fileName = editFileName
        setEditFileId(false)
        setEditFileName(false)
        if (fileName) {
            await renameFile(newName, fileId, roomId)
            queryClient.invalidateQueries(['files', roomId])

        }
    }
    return (<div>

        <div className={styles.BreadcrumbContainer}>
                    Current Path: <BreadcrumbMenu path={currentPath} setCurrentPath={setCurrentPath}/>
        </div>
        <div>
            <Text ta="left" size={"lg"} fw={800}>Folders</Text>
            <div className={styles.FolderContainer}>
                {currentNode?.children.filter(isDirectoryNode).map((node) => {
                    return     (
                        <Card shadow="sm" radius="md" withBorder key={node.name} className={styles.FileCard} onClick={() => {setCurrentPath(currentPath + node.name + "/")}}>
                            <div className={styles.CardIcons}>
                                <ActionIcon variant="filled" aria-label="Settings" onClick={(e) => {
                                    setEditDirectoryModalName(node.name)
                                    e.stopPropagation()
                                }} >
                                    <IconAdjustments style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                </ActionIcon>
                                <ActionIcon color={"red"} variant="filled" aria-label="Delete" onClick={async (e) => {
                                    e.stopPropagation()
                                    await deleteDirectory(currentPath + node.name + "/", roomId)
                                    await queryClient.invalidateQueries(['files', roomId])
                                }}>
                                    <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                </ActionIcon>
                            </div>
                            <Card.Section className={styles.FileCardIcon}>
                                <IconFolder/>
                            </Card.Section>
                            <Text fw={500}>{node.name}</Text>
                        </Card>
                    )
                })}
            </div>
        </div>
        <div>
            <Text ta="left" size={"lg"} fw={800}>Files</Text>
            <div className={styles.FolderContainer}>
            {currentNode?.children.filter(isFileNode).map((node) => {
                return     (
                    <Card shadow="sm" radius="md" withBorder key={node.id} className={styles.FileCard} onClick={() => {getFile(node.id, roomId)}}>
                        <div className={styles.CardIcons}>
                            <ActionIcon variant="filled" aria-label="Settings" onClick={(e) => {
                                setEditFileName(node.name)
                                setEditFileId(node.id)
                                e.stopPropagation()
                            }} >
                                <IconAdjustments style={{ width: '70%', height: '70%' }} stroke={1.5} />
                            </ActionIcon>
                            <ActionIcon
                                color={"red"}
                                variant="filled"
                                aria-label="Delete"
                                onClick={async (e) => {
                                    e.stopPropagation()
                                    await deleteFile(node.id, roomId)
                                    await queryClient.invalidateQueries(['files', roomId])
                                }}>
                                <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                            </ActionIcon>
                        </div>
                        <Card.Section className={styles.FileCardIcon}>
                            <IconFileTypePdf/>
                        </Card.Section>
                        <Text fw={500}>{node.name}</Text>
                    </Card>
                )
            })}
            </div>
        </div>
        <div>
            Upload Files
            <FileUploadBox roomId={roomId} currentPath={currentPath}/>
        </div>
        <EditDirectoryModal open={editDirectoryModalName !== false} onClose={() => setEditDirectoryModalName(false)} onSubmitNewName={submitRenameDirectory} onSubmitNewPath={submitMoveDirectory} path={currentPath} directoryName={editDirectoryModalName || ''}/>
        <EditFileModal
            open={editFileName !== false && editFileId !== false}
            onClose={() => {
                setEditFileName(false)
                setEditFileId(false)
            }}
            onSubmitNewName={submitRenameFile}
            onSubmitNewPath={submitMoveFile}
            path={currentPath}
            fileName={editFileName || ''}
            fileId={editFileId || ''}
        />
    </div>)
}

export default RoomOutlet


type AnchorsProps = {
    path: string
    setCurrentPath: (currentPath:string) => void
}

const BreadcrumbMenu: FC<AnchorsProps> = (props) => {
    // Split the path into segments and filter out empty strings.
    const segments = props.path.split('/').filter(Boolean);

    // Initialize breadcrumbs with the root element.
    const breadcrumbs = [{
        title: '/',
        href: '/'
    }];

    // Build the rest of the breadcrumbs.
    let cumulativePath = '/';
    segments.forEach((segment, index) => {
        cumulativePath += `${segment}/`;
        breadcrumbs.push({
            title: `${segment}/`,
            href: cumulativePath
        });
    });


    return <Breadcrumbs separator="→" >
        {
            breadcrumbs.map((item, index) => (
                <Anchor onClick={() => {props.setCurrentPath(item.href)}} key={index}>
                    {item.title}
                </Anchor>
            ))
        }
    </Breadcrumbs>
}