import '@mantine/core/styles.css';
import '@mantine/dropzone/styles.css';

import React from 'react';
import './App.css';

import Login from "./pages/Login/Login";
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import Authenticate from "./pages/Authenticate/Authenticate";
import { MantineProvider, createTheme } from '@mantine/core';
import Root from "./pages/Root/Root";
import RoomOutlet from "./pages/Home/RoomOutlet/RoomOutlet";
import {QueryClient, QueryClientProvider} from "react-query";


export const API_BASE_URL = process.env.REACT_APP_API_URL ||  `https://${document.location.host}/`
export enum ApiRoutes {
  FileUpload = "api/file/crud",
  FileList = "api/file/list",
  FileMove = "api/file/move_file",
  FileRename = "api/file/rename_file",
  DirectoryMove = "api/file/move_dir",
  DirectoryDelete = "api/file/delete_dir",
  RoomCreate = "api/room/create",
  RoomList = "api/room/list",
}

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <div>
          Select a room from the side menu. <br/>
          If you don't have any rooms yet create a new room
        </div>
      },
      {
        path: "room/:roomId",
        element: <RoomOutlet/>,
      },
    ]
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/authenticate",
    element: <Authenticate/>,
  }
]);

const theme = createTheme({
  /** Put your mantine theme override here */
});
function App() {
  return (
      <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <RouterProvider router={router}/>
      </MantineProvider>
      </QueryClientProvider>
  )
}

export default App;
