import {useStytchUser} from "@stytch/react";
import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Home from "../Home/Home";


const Root:FC = () => {

    const {user} = useStytchUser();
    const navigate = useNavigate()

    // If not logged in navigate to login page
    useEffect(() => {
        if (!user) {
            navigate("/login")
        }
    }, [user, navigate])

    // Stytch is not setting the correct cookie name for the cloudflare middleware, lets copy the cookie over to make sure the middlewere still works
    useEffect(() => {
        // Read the existing cookie
        const existingCookieMatch = document.cookie.match(/(?:^|;\s*)stytch_session\s*=\s*([^;]*)/);

        // Check if the old cookie exists
        if (existingCookieMatch) {
            const existingCookieValue = existingCookieMatch[1];

            // Extract the expiration time from the old cookie
            const expirationMatch = document.cookie.match(/(?:^|;\s*)stytch_session\s*=\s*[^;]*; expires=([^;]*)/);
            const expirationTime = expirationMatch ? expirationMatch[1] : '';

            // Set the new cookie with the copied value and expiration time
            document.cookie = `session_token=${existingCookieValue}; expires=${expirationTime}; path=/;`;
        }
    }, [])

    return (
        <div className="App">
           <Home/>
        </div>
    );
}

export default Root


