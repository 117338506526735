import React, {useEffect} from "react";
import {StytchLogin, useStytchUser} from "@stytch/react";
import {OAuthProviders, Products, StytchLoginConfig} from "@stytch/vanilla-js";
import {useNavigate} from "react-router-dom";

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch

This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const Login = () => {
    const navigate = useNavigate()
    const { user } = useStytchUser();
    useEffect(() => {
        if (user) {
            // Navigate to the root if user is logged in
            navigate("/")
        }
    }, [user, navigate])
    const styles = {
        container: {
            width: "100%",
        },
        buttons: {
            primary: {
                backgroundColor: "#4A37BE",
                borderColor: "#4A37BE",
            },
        },
    };

    function getHostAndProtocol(): string {
        const protocol = window.location.protocol;
        const host = window.location.host;

        return `${protocol}//${host}`;
    }

    const config: StytchLoginConfig = {
        products: [Products.emailMagicLinks, Products.oauth],
        emailMagicLinksOptions: {
            loginRedirectURL: getHostAndProtocol()+ "/authenticate",
            loginExpirationMinutes: 60,
            signupRedirectURL: getHostAndProtocol()+ "/authenticate",
            signupExpirationMinutes: 60,
        },
        oauthOptions: {
            providers: [{ type: OAuthProviders.Google }],
            loginRedirectURL: getHostAndProtocol()+ "/authenticate",
            signupRedirectURL: getHostAndProtocol()+ "/authenticate",
        },
    };

    return <div>
        <StytchLogin config={config} styles={styles} />;
    </div>
};

export default Login;