import {API_BASE_URL, ApiRoutes} from "../../../App";

export const createRoom = async (roomName: string) => {

    const completeResponse = await fetch(`${API_BASE_URL+ ApiRoutes.RoomCreate}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ roomName: roomName }),
    });

    return completeResponse.text()
}


export type RoomList = Array<Room>
export type Room = {room_name: string, room_id: string}
export const listRooms = async (): Promise<RoomList> => {
    const listResponse = await fetch(`${API_BASE_URL+ ApiRoutes.RoomList}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return listResponse.json()
}
