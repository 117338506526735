import {FC, useLayoutEffect, useState} from "react";
import {Button, Modal, TextInput} from "@mantine/core";


type EditDirectoryModalProps = {
    open: boolean
    onClose: () => void
    onSubmitNewName: (directoryName: string) => void
    onSubmitNewPath: (newPath: string) => void
    path: string
    directoryName: string
}
const EditDirectoryModal:FC<EditDirectoryModalProps> = (props) =>  {
    const [path, setPath] = useState(props.path);
    const [name, setName] = useState(props.directoryName);
    const [open, setOpen] = useState(false)

    useLayoutEffect(() => {
        if (props.open && !open) {
            setOpen(true)
            setPath(props.path)
            setName(props.directoryName)
        } else if (!props.open && open) {
            setOpen(false)
        }
    }, [props, open]);

    return (
        <Modal opened={props.open} onClose={props.onClose} title="New Room">
            <TextInput label='Path: (must start and end with "/")' placeholder="/..." value={path} onChange={(event) => setPath(event.currentTarget.value)}/>
            <Button disabled={!path || path.charAt(0) !== "/"} onClick={() => props.onSubmitNewPath(path)}>Move directory to new path</Button>
            <TextInput label="Name:" placeholder="Directory Name" value={name} onChange={(event) => setName(event.currentTarget.value)}/>
            <Button disabled={!name} onClick={() => props.onSubmitNewName(name)}>Rename</Button>
        </Modal>
    );
}

export default EditDirectoryModal