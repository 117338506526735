import {FC, useState} from "react";
import {Button, Modal, TextInput} from "@mantine/core";


type NewRoomModalProps = {
    open: boolean
    onClose: () => void
    onSubmit: (roomName: string) => void
}
const NewRoomModal:FC<NewRoomModalProps> = (props) =>  {
    const [roomName, setRoomName] = useState('');
    return (
        <>
            <Modal opened={props.open} onClose={props.onClose} title="New Room">
                    <TextInput label="Room Name:" placeholder="Room name" value={roomName} onChange={(event) => setRoomName(event.currentTarget.value)}/>
                    <Button onClick={props.onClose}>Cancel</Button>
                    <Button onClick={() => props.onSubmit(roomName)}>Create new room</Button>
            </Modal>

        </>
    );
}

export default NewRoomModal