import styles from './Home.module.css'
import {useStytch} from "@stytch/react";
import React, {FC, useCallback} from "react";
import {Outlet} from "react-router-dom";
import {useDisclosure} from "@mantine/hooks";
import {AppShell, Burger, Button, Text} from "@mantine/core";
import RoomNavBar from "./RoomNavBar/RoomNavBar";

const Home:FC = () => {

    const [opened, { toggle }] = useDisclosure();

    return (
        <AppShell
            header={{ height: 60 }}
            navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened, desktop: opened} }}
            padding="md"
        >
            <AppShell.Header className={styles.AppShellHeader}>
                <Burger opened={!opened} onClick={toggle} size="sm"/>
                <Text ta="center" size={"xl"} fw={800}> Harvey VDR</Text>
                <LogOutButton/>
            </AppShell.Header>

            <RoomNavBar/>

            <AppShell.Main><Outlet/></AppShell.Main>
        </AppShell>
    )

}

export default Home


export const LogOutButton = () => {
    const stytchClient = useStytch();

    const logout = useCallback(() => {
        stytchClient.session.revoke();
    }, [stytchClient]);

    return <Button onClick={logout}>Log out</Button>;
};

