import {API_BASE_URL, ApiRoutes} from "../../../App";


export type VDRFile = {name: string, path: string, id:string}
export type VDRFileList = Array<VDRFile>
export const listFiles = async (roomId: string): Promise<VDRFileList> => {
    const listResponse = await fetch(`${API_BASE_URL+ ApiRoutes.FileList}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ roomId: roomId }),
    });
    return listResponse.json()
}


const openPdfInNewTab = (blob:any) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = '_blank';
    link.click();
};
export const getFile = async (fileID: string, roomId: string): Promise<void> => {


    const createUploadURL = API_BASE_URL + ApiRoutes.FileUpload + '?' +
        'action=get&' +
        'roomId=' + roomId + '&' +
        'fileId=' + fileID;

    const resp = await fetch(createUploadURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const blob = await resp.blob()
    const pdf = new Blob([blob], { type: 'application/pdf' });
    openPdfInNewTab(pdf)
    return;
}


export const moveDirectory = async (oldPath: string, newPath:string, directoryName:string, roomId: string) => {

    const concatanatedOldPath = oldPath + directoryName + "/"
    const concatanatedNewPath = newPath + directoryName + "/"

    const createUploadURL = API_BASE_URL + ApiRoutes.DirectoryMove

    const resp = await fetch(createUploadURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ oldPath: concatanatedOldPath, newPath: concatanatedNewPath, roomId: roomId }),
    });

    return await resp.text()
}

export const renameDirectory = async (path: string, oldName:string, newName:string, roomId: string) => {

    const concatanatedOldPath = path + oldName + "/"
    const concatanatedNewPath = path + newName + "/"

    const createUploadURL = API_BASE_URL + ApiRoutes.DirectoryMove

    const resp = await fetch(createUploadURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ oldPath: concatanatedOldPath, newPath: concatanatedNewPath, roomId: roomId }),
    });

    return await resp.text()
}
export const moveFile = async (newPath: string, fileId:string, roomId: string) => {

    const createUploadURL = API_BASE_URL + ApiRoutes.FileMove

    const resp = await fetch(createUploadURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileId: fileId, roomId: roomId, newPath: newPath }),
    });

    return await resp.text()
}

export const renameFile = async (newName: string, fileId:string, roomId: string) => {

    const createUploadURL = API_BASE_URL + ApiRoutes.FileRename

    const resp = await fetch(createUploadURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileId: fileId, roomId: roomId, newName: newName }),
    });

    return await resp.text()
}

export const deleteFile = async (fileID: string, roomId: string) => {
    const createUploadURL = API_BASE_URL + ApiRoutes.FileUpload + '?' +
        'action=delete&' +
        'roomId=' + roomId + '&' +
        'fileId=' + fileID;

    const resp = await fetch(createUploadURL, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return resp.text();
}

export const deleteDirectory = async (path: string, roomId: string) => {
    const createUploadURL = API_BASE_URL + ApiRoutes.DirectoryDelete

    const resp = await fetch(createUploadURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({path: path, roomId: roomId}),
    });


    return resp.text();
}