import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StytchUIClient} from "@stytch/vanilla-js";
import {StytchProvider} from "@stytch/react";

// Initialize the Stytch client using our project's public token which can be found in the Stytch dashboard
const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || "");


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <StytchProvider stytch={stytch}>
      <App />
      </StytchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
