import styles from './FileUploadBox.module.css'

import {FC, useCallback, useState} from "react";
import {Dropzone, FileRejection, FileWithPath, PDF_MIME_TYPE} from "@mantine/dropzone";
import {IconFileTypePdf, IconUpload} from "@tabler/icons-react";
import { Group, Text, rem } from '@mantine/core';
import {API_BASE_URL, ApiRoutes} from "../../../App";
import {useQueryClient} from "react-query";


const PART_SIZE = 10 * 1024 * 1024

type FileUploadBoxProps = {
    roomId: string
    currentPath: string
}

const FileUploadBox:FC<FileUploadBoxProps> = (props) => {

    const [rejectedFiles, setRejectedFiles] = useState<Array<FileRejection>>([])
    const [acceptedFiles, setAcceptedFiles] = useState<Array<FileWithPath>>([])
    const queryClient = useQueryClient()

    const onDropAny = useCallback((newAcceptedFiles: Array<FileWithPath>, newRejectedFiles: Array<FileRejection>) => {
        setAcceptedFiles(newAcceptedFiles)
        setRejectedFiles(newRejectedFiles)
        if (newAcceptedFiles.length === 0) {
            return
        }

        newAcceptedFiles.forEach(async (acceptedFile) => {
            await uploadFile(acceptedFile, props.roomId, props.currentPath)
            await queryClient.invalidateQueries(['files', props.roomId])
        })
    }, [props, queryClient])

    return (
        <div>
            <Dropzone
                onDrop={() => {}}
                onDropAny={onDropAny}
                maxSize={500 * 1024 ** 2}
                accept={PDF_MIME_TYPE}
                classNames={{root: styles.mantineDropzoneRoot}}
            >
                <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
                    <Dropzone.Accept>
                        <IconUpload
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconUpload
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconFileTypePdf
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Idle>

                    <div>
                        <Text size="xl" inline>
                            Drag pdfs and folders here, or click to select files
                        </Text>
                        <Text size="sm" c="dimmed" inline mt={7}>
                            Attach as many files as you like
                        </Text>
                    </div>
                </Group>
            </Dropzone>
            <div>
                <Text ta="left" size={"lg"} fw={800}>Rejected Files:</Text>
                <ul>
                {rejectedFiles.map((rejectedFile, index) => {
                    return <li key={index}>
                        {rejectedFile.file.name}
                    </li>
                })}
                </ul>
            </div>
            <div>
                <Text ta="left" size={"lg"} fw={800}>Approved Files:</Text>
                <ul>
                    {acceptedFiles.map((acceptedFile, index) => {
                        return (
                            <li key={index}>
                                {acceptedFile.name}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}



const uploadFile = async (file: FileWithPath, roomId: string, currentPath: string) => {
    if (!file.path) {
        throw new Error("File path not found")
    }

    const createUploadURL = API_BASE_URL + ApiRoutes.FileUpload + '?' +
        'action=' + "mpu-create" + '&' +
        'roomId=' + roomId + '&' +
        'filePath=' + file.path + '&' +
        'currentPath=' + currentPath;

    const resp = await fetch(createUploadURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
    }
    const body = await resp.json()
    const uploadId = body.uploadId
    const fileId = body.key


    const partCount = Math.ceil(file.size / PART_SIZE);
    const uploadedParts: any[] = [];

    const uploadPromises = Array.from({ length: partCount }, (_, index) => uploadPart(file, uploadId, index, roomId, fileId, currentPath));

    try {
        // Wait for all part uploads to complete
        uploadedParts.push(...await Promise.all(uploadPromises));
    } catch (error) {
        console.error('Error uploading parts:', error);
        // Handle error as needed
    }

    // Complete the multipart upload
    const completeUploadUrl = API_BASE_URL + ApiRoutes.FileUpload + '?' +
        'action=' + "mpu-complete" + '&' +
        'roomId=' + roomId + '&' +
        'filePath=' + file.path + '&' +
        'fileId=' + fileId + '&' +
        'uploadId=' + uploadId + '&' +
        'currentPath=' + currentPath;
    const completeResponse = await fetch(completeUploadUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ parts: uploadedParts }),
    });

    if (completeResponse.ok) {
        console.log("🎉 Successfully completed multipart upload");
    } else {
        console.error('Error completing multipart upload:', await completeResponse.text());
    }

    return body;
}

const uploadPart = async (file: FileWithPath, uploadId: string,  index: number, roomId:string, fileId: string, currentPath: string): Promise<any> => {
    const start = PART_SIZE * index;
    const end = Math.min(start + PART_SIZE, file.size);
    const blobPart = file.slice(start, end);

    const formData = new FormData();
    formData.append('file', blobPart);

    const uploadPartUrl = API_BASE_URL + ApiRoutes.FileUpload + '?' +
        'action=' + "mpu-uploadpart" + '&' +
        'roomId=' + roomId + '&' +
        'filePath=' + file.path + '&' +
        'fileId=' + fileId + '&' +
        'uploadId=' + uploadId + '&' +
        'partNumber=' + index+1 + '&' +
        'currentPath=' + currentPath;
    const response = await fetch(uploadPartUrl, {
        method: 'PUT',
        body: formData,
    });

    return response.json();
};

export default FileUploadBox