import {VDRFileList} from "../RoomNavBar/fileEndpoints";

export type FileNode = {
    type: 'file';
    id: string;
    name: string;
};

export type DirectoryNode = {
    type: 'directory';
    name: string;
    children: Array<FileNode | DirectoryNode>;
};

export function isFileNode(node: FileNode | DirectoryNode): node is FileNode {
    return node.type === 'file';
}

export function isDirectoryNode(node: FileNode | DirectoryNode): node is DirectoryNode {
    return node.type === 'directory';
}


export function createFileHierarchy(input: VDRFileList): DirectoryNode {
    const root: DirectoryNode = { type: 'directory', name: "root", children: [] };

    input.forEach(item => {
        let currentLevel = root.children;

        const paths = item.path.split('/').filter(p => p.length > 0);

        paths.forEach(path => {
            let directory = currentLevel.find(child => isDirectoryNode(child) && child.name === path) as DirectoryNode;

            if (!directory) {
                directory = { type: 'directory', name: path, children: [] };
                currentLevel.push(directory);
            }

            currentLevel = directory.children;
        });

        currentLevel.push({ type: 'file', name: item.name, id: item.id });
    });

    return root;
}
export function findNodeByPath(root: DirectoryNode, path: string):  DirectoryNode | undefined {
    const segments = path.split('/').filter(segment => segment.length > 0);

    let currentNode: DirectoryNode | undefined = root;

    for (const segment of segments) {
        if (currentNode === undefined || !('children' in currentNode)) {
            // If currentNode is a FileNode, it can't have children.
            return undefined;
        }

        const nextNode: DirectoryNode | FileNode | undefined = currentNode.children.find(child => child.name === segment);


        if (!nextNode || !isDirectoryNode(nextNode)) {
            // If no child matches the segment, the path is invalid.
            return undefined;
        }

        currentNode = nextNode;
    }

    return currentNode;
}